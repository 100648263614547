<template>
  <div class="media-layout">
    <section class="hero hero-media">
      <div class="container">
        <div class="__content">
          <h1 class="hero-title">Медиа-центр</h1>

          <div class="hero-media--meta display--flex">
            <div class="hero-media--tags btn-action display--flex">
              <button
                @click="changeTag('Все')"
                :class="{ active: currentTag === 'Все' }"
                class="btn btn-o btn-sm"
              >
                Все
              </button>
              <button
                @click="changeTag('Новости')"
                :class="{ active: currentTag === 'Новости' }"
                class="btn btn-o btn-sm"
              >
                Новости
              </button>
              <button
                @click="changeTag('Статьи')"
                :class="{ active: currentTag === 'Статьи' }"
                class="btn btn-o btn-sm"
              >
                Статьи
              </button>
              <button
                @click="changeTag('СМИ')"
                :class="{ active: currentTag === 'СМИ' }"
                class="btn btn-o btn-sm"
              >
                СМИ
              </button>
            </div>
            <div class="hero-media--action display--flex">
              <!-- <form>
                <input
                  type="search"
                  class="form-control form-control--sm"
                  placeholder="Поиск"
                />
              </form> -->
              <button
                @click="toSubscribe"
                type="button"
                class="btn btn-accent btn-sm"
              >
                Подписаться на рассылку
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="currentTag === 'Все'">
      <div v-if="newsList.length">
        <div v-for="(newsSet, index) of newsList" :key="index">
          <section class="index-section s-media">
            <div class="container">
              <div class="layout-media display--grid">
                <div
                  v-if="newsList.length"
                  class="layout-main display--grid grid-2"
                >
                  <div class="post-lg" v-if="bigNews[index]">
                    <router-link
                      :to="`/news/${bigNews[index].slug}`"
                      class="post-thumbnail"
                    >
                      <img :src="`${bigNews[index].full_image}`" alt="" />
                    </router-link>
                    <div class="post-meta">
                      <router-link
                        :to="`/news/${bigNews[index].slug}`"
                        class="post-title"
                        >{{ bigNews[index].title }}</router-link
                      >
                      <p class="post-excerpt">
                        {{ bigNews[index].short_text }}
                      </p>
                      <div class="post-tags display--flex">
                        <span v-if="index === 0" class="tag-accent">New</span>
                        <span
                          v-for="tag of bigNews[index].tags.data"
                          :key="tag.id"
                          >{{ tag.title }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div v-for="news of newsSet" :key="news.id" class="post-sm">
                    <router-link
                      :to="`/news/${news.slug}`"
                      class="post-thumbnail"
                    >
                      <img :src="`${news.middle_image}`" alt="" />
                    </router-link>
                    <div class="post-meta">
                      <router-link
                        :to="`/news/${news.slug}`"
                        class="post-title"
                        >{{ news.title }}</router-link
                      >
                      <div class="post-tags display--flex">
                        <span v-for="tag of news.tags.data" :key="tag.id">{{
                          tag.title
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <aside class="layout-aside">
                  <story-list-block></story-list-block>
                </aside>
              </div>
            </div>
          </section>
          <component :is="typesOfContent[index]" />
        </div>
      </div>
      <div v-else class="container index-section layout-item--title">
        Загрузка...
      </div>
    </div>

    <div v-else>
      <div class="container">
        <section class="index-section">
          <div class="layout-media display--grid">
            <div
              v-if="newsList.length"
              class="layout-main display--grid grid-2"
            >
              <div v-for="news of newsList" :key="news.id" class="post-sm">
                <router-link :to="`/news/${news.slug}`" class="post-thumbnail">
                  <img :src="`${news.middle_image}`" alt="" />
                </router-link>
                <div class="post-meta">
                  <router-link :to="`/news/${news.slug}`" class="post-title">{{
                    news.title
                  }}</router-link>
                  <div class="post-tags display--flex">
                    <span v-for="tag of news.tags.data" :key="tag.id">{{
                      tag.title
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="loadTag" class="layout-item--title">Загрузка...</div>
            <div v-if="!newsList.length && !loadTag" class="layout-item--title">
              Ничего не найдено
            </div>
            <aside class="layout-aside">
              <story-list-block></story-list-block>
            </aside>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import SubscribeBlock from "../../components/banners/subscribe-block/SubscribeBlock.vue";
import RateTheLevel from "../../components/banners/rate-the-level/RateTheLevel.vue";
import ProductSlider from "../../components/sliders/product-slider/ProductSlider.vue";
import { useHead } from "@vueuse/head";
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import StoryListBlock from "../../components/story-list-block/StoryListBlock.vue";

export default {
  name: "media-page",
  components: {
    SubscribeBlock,
    RateTheLevel,
    ProductSlider,
    StoryListBlock,
  },
  inject: ["backUrl"],

  setup() {
    let store = useStore();
    useHead({
      title: "Новости и статьи в сфере эмоционального интеллекта",
      meta: [
        {
          property: "og:description",
          content:
            "Публикуем новости, интересные исследования, статьи в сфере эмоционального интеллекта. А также пишем собственные эмоционально-интеллектуальные рассказы для практического применения и разбора жизненных ситуаций.",
        },
        {
          property: "og:keywords",
          content:
            "эмоциональный интеллект в мире, эмоциональный интеллект в России, новости эмоциональный интеллект, исследования эмоциональный интеллект, книга эмоциональный интеллект, онлайн эмоциональный интеллект, психология эмоций",
        },
      ],
    });

    const perPage = 7;
    let newsList = ref([]),
      page = ref(1),
      bigNews = ref([]),
      state = ref(1),
      typesOfContent = ref(["subscribe-block"]),
      loadingNews = ref(false);

    let currentTag = ref("Все"),
      loadTag = ref(false);

    let nextNews = computed(() => store.state.news.news),
      newsTotalCount = computed(() => store.state.news.newsTotalCount);

    let loadMoreMews = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 1520;
      if (
        bottomOfWindow &&
        !loadingNews.value &&
        perPage * (page.value + 1) <= newsTotalCount.value
      ) {
        loadingNews.value = true;
        page.value++;
        state.value === 3 ? (state.value = 1) : state.value++;
        store
          .dispatch("news/getNews", { perPage, page: page.value })
          .then(() => {
            newsList.value.push(nextNews.value);
            bigNews.value.push(newsList.value[page.value - 1].shift());
            loadingNews.value = false;
          });

        switch (state.value) {
          case 1:
            typesOfContent.value.push("subscribe-block");
            break;
          case 2:
            typesOfContent.value.push("product-slider");
            break;
          case 3:
            typesOfContent.value.push("rate-the-level");
            break;
          default:
            typesOfContent.value.push("product-slider");
            break;
        }
      }
    };
    let changeTag = (tag) => {
      newsList.value = [];
      page.value = 1;
      bigNews.value = [];
      state.value = 1;
      currentTag.value = tag;
      loadTag.value = true;
      if (tag === "Все") {
        store
          .dispatch("news/getNews", { perPage, page: page.value })
          .then(() => {
            newsList.value.push(nextNews.value);
            bigNews.value.push(newsList.value[0].shift());
            loadTag.value = false;
          });
      } else {
        store.dispatch("news/getNewsByTag", tag).then(() => {
          newsList.value = nextNews.value;
          loadTag.value = false;
        });
      }
    };

    let toSubscribe = () => {
      document
        .querySelector(".subscribe-banner")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    };

    onBeforeMount(() => {
      store.dispatch("news/getNews", { perPage, page: page.value }).then(() => {
        newsList.value.push(nextNews.value);
        bigNews.value.push(newsList.value[0].shift());
      });
    });

    onMounted(() => {
      window.addEventListener("scroll", loadMoreMews);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", loadMoreMews);
    });

    watch(
      () => currentTag.value,
      (newValue) => {
        if (newValue === "Все") {
          window.addEventListener("scroll", loadMoreMews);
        } else {
          window.removeEventListener("scroll", loadMoreMews);
        }
      }
    );

    return {
      newsList,
      page,
      perPage,
      nextNews,
      loadMoreMews,
      bigNews,
      state,
      typesOfContent,
      loadingNews,
      newsTotalCount,
      toSubscribe,
      currentTag,
      changeTag,
      loadTag,
    };
  },
};
</script>

<style scoped>
.media-layout {
  padding-bottom: 80px;
}

.hero-media--meta {
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 24px;
}

.hero-media--tags {
  flex: 1.5;
  flex-flow: row wrap;
}

.hero-media--tags .btn {
  margin: 0 8px 8px 0;
}

.hero-media--tags .btn + .btn {
  margin-left: 0;
}

.hero-media--action {
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.hero-media--action form {
  max-width: 150px;
  margin-right: 8px;
}

.index-section + .s-media:not(.bg-light),
.index-section + .s-banner,
.s-banner + .index-section {
  padding-top: 0;
}

section:not(.hero) + .s-banner,
section:not(.hero) + .s-products--slider {
  padding-bottom: 0;
}

.post-lg {
  grid-column: span 2;
}

.post-thumbnail {
  display: block;
}

.post-thumbnail img {
  display: block;
  width: 100%;
  object-fit: cover;
}

a.post-thumbnail:hover {
  opacity: 0.7;
}

.post-sm .post-thumbnail {
  margin: 0 0 12px 0;
}

.post-lg .post-thumbnail,
.s-post--entry .post-thumbnail {
  margin: 0 0 20px 0;
}

.post-lg .post-thumbnail img,
.s-post--entry .post-thumbnail img {
  height: 380px;
  border-radius: 10px;
}

.post-sm .post-thumbnail img {
  height: 180px;
  border-radius: 4px;
}

.post-meta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.post-title {
  color: var(--color);
  text-decoration: none;
}

.post-lg .post-title {
  font-size: 46px;
  line-height: 51px;
  letter-spacing: -0.02em;
  text-align: left;
}

.post-sm .post-title {
  font-size: 24px;
  line-height: 29px;
  text-align: left;
}

.post-title:hover {
  color: var(--accent);
}

.post-excerpt {
  margin-top: 4px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
}

.post-tags {
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0 0 0;
}

.post-tags span {
  display: inline-block;
  margin: 8px 8px 0 0;
  padding: 3px 7px 4px;
  color: var(--color);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  box-shadow: none;
  font-size: 13px;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}

.post-tags span.tag-accent {
  color: #ffffff;
  background-color: var(--accent);
  border-color: var(--accent);
}

.btn.active {
  color: #ffffff;
  background-color: var(--accent);
  border-color: var(--accent);
}

@media (max-width: 767px) {
  .hero-media--meta {
    flex-direction: column;
    width: 100%;
  }

  .hero-media--tags .btn + .btn {
    margin-top: 0;
  }

  .hero-media--action {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  .hero-media--action form {
    width: 100%;
    max-width: 100%;
    margin: 8px 0 0 0;
  }

  .hero-media--action .form-control[type="search"] {
    padding-right: 16px;
    padding-left: 40px;
    background-color: transparent;
    background-position: center left 10px;
    border-color: transparent;
  }

  .hero-media--action .form-control[type="search"]:focus {
    background-color: #ffffff;
  }

  .post-lg {
    grid-column: span 1;
  }

  .post-lg .post-thumbnail img,
  .s-post--entry .post-thumbnail img {
    height: 160px;
  }

  .post-lg .post-title,
  .post-sm .post-title {
    font-size: 18px;
    line-height: 22px;
  }

  .post-excerpt {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
